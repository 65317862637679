.accordion__title--disabled,
.accordion__title--disabled:focus,
.accordion__title--disabled:hover,
.accordion__title--disabled:active {
  transition: background-color 0.2s ease-in-out;
  pointer-events: none;
  background-color: rgba(244, 67, 54, 0.6);
}

.accordion__title--disabled button {
  pointer-events: all;
}
